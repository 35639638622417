<template>
  <main>
    <trac-loading v-if="isLoading" />
    <div
      class="flex justify-center flex-col items-center shadow-md mt-24 max-w-4xl mx-auto"
    >
      <div class="flex items-center justify-center pb-3">
        <img class=" w-64" src="@/assets/images/fcmb.jpg" alt="" />
      </div>
      <form
        autocomplete="off"
        class="px-16 w-full mt-10"
        @submit.prevent="signUserUp"
      >
        <h1 class="text-lg font-medium text-accentDark">Create Account</h1>
        <!--  -->

        <div class="grid grid-cols-2 col-gap-5 row-gap-6 mt-5 mb-8">
          <trac-validation-provider
            name="first name"
            rules="required|alpha_spaces"
            v-slot="{ errors }"
          >
            <trac-input
              v-model="authUser.firstName"
              type="text"
              placeholder="First Name"
            />
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>
          <!--  -->
          <trac-validation-provider
            name="last name"
            rules="required|alpha_spaces"
            v-slot="{ errors }"
          >
            <trac-input
              v-model="authUser.lastName"
              type="text"
              placeholder="Last Name"
            />
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>

          <trac-validation-provider
            name="phone number"
            rules="required|integer"
            v-slot="{ errors }"
          >
            <trac-input
              v-model="authUser.phoneNumber"
              type="number"
              placeholder="Phone Number"
            />
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>
          <!--  -->
          <trac-validation-provider rules="required|email" v-slot="{ errors }">
            <trac-input
              v-model="authUser.email"
              type="email"
              placeholder="Email"
            />
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>

          <trac-validation-provider rules="required" v-slot="{ errors }">
            <trac-input
              v-model="authUser.password"
              type="password"
              placeholder="Password"
              specified="password"
              ref="emails"
              autocomplete="nope"
            />
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>

          <trac-validation-provider
            rules="required"
            v-slot="{ errors }"
            tag="div"
          >
            <trac-input
              v-model="authUser.businessName"
              type="text"
              placeholder="Business Name"
            />
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>

          <trac-validation-provider
            rules="required"
            v-slot="{ errors }"
            tag="div"
            name="business category"
            class="relative"
          >
            <div class="relative w-full">
              <label class="relative w-full cursor-pointer">
                <input
                  v-click-outside="close"
                  @click="active = !active"
                  readonly
                  class="border cursor-pointer py-2 text-accentDark blacj leading-6 mt-x pl-5 w-full text-xs rounded input-area"
                  placeholder=" "
                  v-model="authUser.businessCategory"
                />
                <span class="text-xs leading-5 text-primaryBlue allin">{{
                  placeholder
                }}</span>
                <svg
                  class="fill-current h-4 w-4 absolute right-0 -mt-8 mr-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </label>
              <ul
                v-if="active"
                class="w-64 absolute capitalize z-10 top-0 mt-20 mb-5 h-64 overflow-y-auto rounded border white-gradient cursor-pointer text-primaryBlue pt-1"
              >
                <li
                  :class="
                    authUser.businessCategory == category
                      ? ' bg-primaryBlue text-white rounded-none hover:bg-primaryBlue px-4 py-2 whitespace-no-wrap text-xs'
                      : 'hover:bg-accentLight bg-white text-xs py-2 px-4 block whitespace-no-wrap rounded'
                  "
                  @click="setValue(category)"
                  v-for="(category, index) in categories"
                  :key="index"
                >
                  {{ category }}
                </li>
              </ul>
            </div>
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>

          <trac-validation-provider rules="required" v-slot="{ errors }">
            <trac-input
              disabled
              v-model="authUser.refCode"
              type="text"
              placeholder="Referral code"
            />
            <trac-input-error v-if="errors[0]">{{
              errors[0]
            }}</trac-input-error>
          </trac-validation-provider>
        </div>

        <!-- <div class="mt-8 flex items-center justify-center flex-col">
          <div class="flex">
            <trac-button
              type="submit"
              v-if="current !== 1"
              @button-clicked="current -= 1"
              variant="outline"
              class="w-48 mr-5"
              ><div class="leading-7">PREVIOUS</div></trac-button
            >
            <trac-button
              type="submit"
              v-if="current == 1"
              @button-clicked="current += 1"
              variant="outline"
              class="w-48"
              ><div class="leading-7">NEXT</div></trac-button
            >
            <trac-button
              type="submit"
              v-if="current > 1"
              @button-clicked="current += 1"
              class="w-48"
              ><div class="leading-7">
                {{ current < 3 ? "NEXT" : "SIGN UP" }}
              </div></trac-button
            >
          </div>
          <p class="text-xs text-primaryGray text-center mt-6">
            By clicking “Sign Up” I agree to Traction Terms of Service and
            Privacy. <br />
            Already have an account?
            <router-link to="/login" class="text-primaryBlue font-medium"
              >Login</router-link
            >
          </p>
        </div> -->
        <p class="text-xs text-primaryGray text-center mt-2 pb-6">
          By clicking “Sign Up” I agree to Traction Terms of Service and
          Privacy.
          <br />
          Already have an account?
          <router-link to="/fcmb-login" class="text-primaryBlue font-medium"
            >Login</router-link
          >
        </p>
        <div class="mb-10 flex justify-center items-center">
          <trac-button type="submit" class="w-48"
            ><div class="leading-7">
              SIGN UP
            </div></trac-button
          >
        </div>
      </form>
    </div>
  </main>
</template>

<script>
import { FETCH_ALL_DATA } from "../browser-db-config/utilityMethods";
import axios from "axios";
import ClickOutside from "vue-click-outside";
import { eventBus } from "./../main";
import {
  GET_USER_TOKEN,
  SAVE_LOCAL_DB_DATA,
} from "../browser-db-config/localStorage";
import {
  CLEAR_ALL_DATA_IN_DB,
  CLEAR_LIST,
} from "../browser-db-config/indexedDB";

export default {
  data() {
    return {
      placeholder: "Select Category",
      active: false,
      authUser: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
        businessName: "",
        businessCategory: "",
        refCode: "FCMB",
      },
      categories: [
        "Retail",
        "Services",
        "Fashion & Accessories",
        "Food & Beverage",
        "Electronics & Computers",
        "Furniture",
        "Agro business",
        "Beauty, Saloon & Spa",
        "Fitness & Gyms",
        "Laundry & Dry cleaning",
        "Interior decorator",
        "Event planner",
        "Designer/Software developer",
        "Other retail",
        "Other service provider",
      ],
    };
  },
  directives: {
    ClickOutside,
  },
  methods: {
    close() {
      this.active = false;
    },
    setValue(x) {
      this.authUser.businessCategory = x;
    },
    fetchLoyaltyPointToken() {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");
      myHeaders.append("Authorization", "Bearer " + GET_USER_TOKEN());

      // Fetch Token
      fetch(`https://tractionapp-stage.herokuapp.com/auth/loyalty/access`, {
        method: "POST",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(async (data) => {
          this.$store.dispatch("DOWNLOAD_CUSTOMERS_POINTS", data);
          FETCH_ALL_DATA();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async signUserUp() {
      this.isLoading = true;
      if (this.authUser.password == "") {
        eventBus.$emit("trac-alert", {
          message: "Please put in a password",
        });
        return;
      }
      if (this.authUser.businessCategory == "") {
        eventBus.$emit("trac-alert", {
          message: "Please select a category from the dropdown",
        });
        return;
      }
      const payload = {
        first_name: this.authUser.firstName,
        last_name: this.authUser.lastName,
        email: this.authUser.email,
        password: this.authUser.password,
        referral_code: this.authUser.refCode,
        role: "admin",
        user_type: this.authUser.businessCategory,
      };

      await this.$store.dispatch("SIGN_USER_UP", payload);
      const res = this.$store.getters["GET_SIGN_UP_RES"];
      if (res.status) {
        this.saveData("traction-app-user-data", res.data);
        await this.signUserBusinessUp();
      } else {
        // alert(res.message || "Error: Netowrk error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Netowrk error occured.",
        });
      }
      this.isLoading = false;
    },
    async signUserBusinessUp() {
      this.isLoading = true;
      const payload = {
        name: this.authUser.businessName,
        email: this.authUser.email,
        category: this.authUser.businessCategory,
        phone: this.authUser.phoneNumber,
        address: {
          country: "",
          city: "",
          state: "",
          street: "",
        },
      };

      await this.$store.dispatch("SIGN_USER_BUSINESS_UP", payload);
      const res = this.$store.getters["GET_SIGN_USER_BUSINESS_UP_RES"];
      if (res.status) {
        await this.logUserIn();
      } else {
        // alert(res.message || "Error: Netowrk error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Netowrk error occured.",
        });
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async logUserIn() {
      const payload = {
        email: this.authUser.email,
        password: this.authUser.password,
      };
      await this.$store.dispatch("LOG_USER_IN", payload);
      const res = this.$store.getters["GET_LOGIN_RES"];

      if (res.status) {
        await CLEAR_ALL_DATA_IN_DB();
        // CLEAR_LIST();
        this.saveData("traction-app-user-data", res.data);
        await this.fetchBusinessUserDetails();
      } else {
        // alert(res.message || "Error: Netowrk error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Netowrk error occured.",
        });
        this.isLoading = false;
      }
    },
    async fetchBusinessUserDetails() {
      await this.$store.dispatch("FETCH_BUSINESS_USER_DETAILS");
      const res = this.$store.getters["GET_BUSINESS_USER_RES"];

      if (res.status) {
        this.saveData("traction-app-current-user-business-data", res.data);

        this.fetchLoyaltyPointToken();
        // this.$router.push({ name: "Payments" });
        this.$router.push({ name: "Dashboard" });
      } else {
        // alert(res.message || "Error: Netowrk error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Netowrk error occured.",
        });
        this.isLoading = false;
      }
    },
    saveData(localStorageName, dataToStore) {
      SAVE_LOCAL_DB_DATA(localStorageName, dataToStore);
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      const email = vm.$route.params.email;
      const checkEmail = await vm.$store.dispatch("CHECK_EMAIL", { email });
      if (checkEmail.status) {
        vm.$router.replace({
          name: "FcmbLogin",
          params: {
            email,
          },
        });
      } else {
        next();
      }
    });
  },
  async created() {
    const url =
      process.env.VUE_APP_FCMBURL || `https://businesszone.fcmb.com/api`;
    let token = null;
    try {
      const response = await axios.post(`${url}/gettoken`, {
        user_id: process.env.VUE_APP_FCMBID,
        password: process.env.VUE_APP_FCMBPASSWORD,
      });
      token = response.data.data.token;
    } catch (error) {
      console.log(error);
    }
    if (token) {
      const getVendor = await axios.get(
        `${url}/user/${this.$route.params.email}&api_user_id=14024`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let {
        businessname,
        firstname,
        lastname,
        phonenumber,
        email,
      } = getVendor.data.data;
      this.authUser.firstName = firstname;
      this.authUser.lastName = lastname;
      this.authUser.phoneNumber = phonenumber;
      this.authUser.email = email;
      this.authUser.businessName = businessname;
    }
  },
};
</script>
<style scoped>
.allin {
  pointer-events: none;
  position: absolute;
  left: 1rem;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
  position: absolute;
}

.input-area:focus + span,
.input-area:not(:placeholder-shown) + span {
  opacity: 1;
  transform: scale(0.75) translateY(-100%) translateX(-30px);
  left: 1.6rem;
  top: -0.45rem;
  background: white;
  padding: 0 5px;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.input-area:focus + .input-area:-webkit-autofill + span {
  opacity: 0;
}

.input-area:-webkit-autofill .input-area:placeholder-shown {
  opacity: 0 !important;
}
</style>
