var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[(_vm.isLoading)?_c('trac-loading'):_vm._e(),_c('div',{staticClass:"flex justify-center flex-col items-center shadow-md mt-24 max-w-4xl mx-auto"},[_vm._m(0),_c('form',{staticClass:"px-16 w-full mt-10",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.signUserUp($event)}}},[_c('h1',{staticClass:"text-lg font-medium text-accentDark"},[_vm._v("Create Account")]),_c('div',{staticClass:"grid grid-cols-2 col-gap-5 row-gap-6 mt-5 mb-8"},[_c('trac-validation-provider',{attrs:{"name":"first name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"type":"text","placeholder":"First Name"},model:{value:(_vm.authUser.firstName),callback:function ($$v) {_vm.$set(_vm.authUser, "firstName", $$v)},expression:"authUser.firstName"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('trac-validation-provider',{attrs:{"name":"last name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"type":"text","placeholder":"Last Name"},model:{value:(_vm.authUser.lastName),callback:function ($$v) {_vm.$set(_vm.authUser, "lastName", $$v)},expression:"authUser.lastName"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('trac-validation-provider',{attrs:{"name":"phone number","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"type":"number","placeholder":"Phone Number"},model:{value:(_vm.authUser.phoneNumber),callback:function ($$v) {_vm.$set(_vm.authUser, "phoneNumber", $$v)},expression:"authUser.phoneNumber"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('trac-validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"type":"email","placeholder":"Email"},model:{value:(_vm.authUser.email),callback:function ($$v) {_vm.$set(_vm.authUser, "email", $$v)},expression:"authUser.email"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('trac-validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{ref:"emails",attrs:{"type":"password","placeholder":"Password","specified":"password","autocomplete":"nope"},model:{value:(_vm.authUser.password),callback:function ($$v) {_vm.$set(_vm.authUser, "password", $$v)},expression:"authUser.password"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('trac-validation-provider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"type":"text","placeholder":"Business Name"},model:{value:(_vm.authUser.businessName),callback:function ($$v) {_vm.$set(_vm.authUser, "businessName", $$v)},expression:"authUser.businessName"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('trac-validation-provider',{staticClass:"relative",attrs:{"rules":"required","tag":"div","name":"business category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"relative w-full"},[_c('label',{staticClass:"relative w-full cursor-pointer"},[_c('input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"},{name:"model",rawName:"v-model",value:(_vm.authUser.businessCategory),expression:"authUser.businessCategory"}],staticClass:"border cursor-pointer py-2 text-accentDark blacj leading-6 mt-x pl-5 w-full text-xs rounded input-area",attrs:{"readonly":"","placeholder":" "},domProps:{"value":(_vm.authUser.businessCategory)},on:{"click":function($event){_vm.active = !_vm.active},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.authUser, "businessCategory", $event.target.value)}}}),_c('span',{staticClass:"text-xs leading-5 text-primaryBlue allin"},[_vm._v(_vm._s(_vm.placeholder))]),_c('svg',{staticClass:"fill-current h-4 w-4 absolute right-0 -mt-8 mr-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])]),(_vm.active)?_c('ul',{staticClass:"w-64 absolute capitalize z-10 top-0 mt-20 mb-5 h-64 overflow-y-auto rounded border white-gradient cursor-pointer text-primaryBlue pt-1"},_vm._l((_vm.categories),function(category,index){return _c('li',{key:index,class:_vm.authUser.businessCategory == category
                    ? ' bg-primaryBlue text-white rounded-none hover:bg-primaryBlue px-4 py-2 whitespace-no-wrap text-xs'
                    : 'hover:bg-accentLight bg-white text-xs py-2 px-4 block whitespace-no-wrap rounded',on:{"click":function($event){return _vm.setValue(category)}}},[_vm._v(" "+_vm._s(category)+" ")])}),0):_vm._e()]),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])}),_c('trac-validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('trac-input',{attrs:{"disabled":"","type":"text","placeholder":"Referral code"},model:{value:(_vm.authUser.refCode),callback:function ($$v) {_vm.$set(_vm.authUser, "refCode", $$v)},expression:"authUser.refCode"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('p',{staticClass:"text-xs text-primaryGray text-center mt-2 pb-6"},[_vm._v(" By clicking “Sign Up” I agree to Traction Terms of Service and Privacy. "),_c('br'),_vm._v(" Already have an account? "),_c('router-link',{staticClass:"text-primaryBlue font-medium",attrs:{"to":"/fcmb-login"}},[_vm._v("Login")])],1),_c('div',{staticClass:"mb-10 flex justify-center items-center"},[_c('trac-button',{staticClass:"w-48",attrs:{"type":"submit"}},[_c('div',{staticClass:"leading-7"},[_vm._v(" SIGN UP ")])])],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center pb-3"},[_c('img',{staticClass:" w-64",attrs:{"src":require("@/assets/images/fcmb.jpg"),"alt":""}})])}]

export { render, staticRenderFns }